
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Header from '@/modules/landingPage/components/layout/Header.vue';
import Footer from '@/modules/landingPage/components/layout/Footer.vue';
/* INTERFACES */
/* STORE */

@Component({
  components: {
      SvgIcon,
      Header,
      Footer,
  },
})
export default class ProvacyPolicies extends Vue {

socialMedia= [
    {
        image: 'CR-AR.png',
        country: 'Argentina',
        ig: '',
        facebook: '',
        whataspp: ''
    },
    {
        image: 'CR-BR.png',
        country: 'Brasil',
        ig: '',
        facebook: '',
        whataspp: ''
    },
    {
        image: 'CR-CL.png',
        country: 'Chile',
        ig: '',
        facebook: '',
        whataspp: ''
    },
    {
        image: 'CR-CO.png',
        country: 'Colombia',
        ig: '',
        facebook: '',
        whataspp: ''
    },
    {
        image: 'CR-CO.png',
        country: 'Panamá',
        ig: '',
        facebook: '',
        whataspp: ''
    },
    {
        image: 'CR-CL.png',
        country: 'Rep. Dominicana',
        ig: '',
        facebook: '',
        whataspp: ''
    },
    {
        image: 'CR-BR.png',
        country: 'Perú',
        ig: '',
        facebook: '',
        whataspp: ''
    },
    {
        image: 'CR-AR.png',
        country: 'España',
        ig: '',
        facebook: '',
        whataspp: ''
    },
]

}
